const mode: 'prod' | 'dev' | 'localhost'
    = 'dev'

const hotelAdmin = {
    prod: 'http://193.112.1.25:9501',
    dev: 'http://106.53.196.85:9501',
    localhost: ''
}

export const baseUrl = {
    hotelAdmin: hotelAdmin[mode]
}


