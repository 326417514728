import { useLayoutStore } from '/@/store/modules/layout'
import axios, {
    AxiosError,
    AxiosInstance,
    AxiosInterceptorManager,
    AxiosRequestConfig,
    AxiosResponse,
    CancelStatic,
    CancelTokenStatic
} from 'axios'
import { ElLoading, ElNotification } from 'element-plus'
import { baseUrl } from '/@/config/request'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// import Qs from 'qs'

let loading:{close():void}

// 重写axios接口用做返回
interface AxiosResponseInstance {
    (config: AxiosRequestConfig): Promise<IResponse<any>>;

    (url: string, config?: AxiosRequestConfig): Promise<IResponse<any>>;

    defaults: AxiosRequestConfig;
    interceptors: {
      request: AxiosInterceptorManager<AxiosRequestConfig>;
      response: AxiosInterceptorManager<AxiosResponse>;
    };

    getUri (config?: AxiosRequestConfig): string;
    request<T = any, R = IResponse<T>> (config: AxiosRequestConfig): Promise<R>;
    get<T = any, R = IResponse<T>> (url: string, config?: AxiosRequestConfig): Promise<R>;
    delete<T = any, R = IResponse<T>> (url: string, config?: AxiosRequestConfig): Promise<R>;
    head<T = any, R = IResponse<T>> (url: string, config?: AxiosRequestConfig): Promise<R>;
    options<T = any, R = IResponse<T>> (url: string, config?: AxiosRequestConfig): Promise<R>;
    post<T = any, R = IResponse<T>> (url: string, data?: any, config?: AxiosRequestConfig): Promise<R>;
    put<T = any, R = IResponse<T>> (url: string, data?: any, config?: AxiosRequestConfig): Promise<R>;
    patch<T = any, R = IResponse<T>> (url: string, data?: any, config?: AxiosRequestConfig): Promise<R>;
  }


export interface AxiosStatic extends AxiosResponseInstance {
    create(config?: AxiosRequestConfig): AxiosInstance;
    Cancel: CancelStatic;
    CancelToken: CancelTokenStatic;
    isCancel(value: any): boolean;
    all<T>(values: (T | Promise<T>)[]): Promise<T[]>;
    spread<T, R>(callback: (...args: T[]) => R): (array: T[]) => R;
    isAxiosError(payload: any): payload is AxiosError;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const request:AxiosStatic = axios.create({
    // API 请求的默认前缀
    baseURL: baseUrl.hotelAdmin,
    timeout: 30000, // 请求超时时间
    headers: {
        // 'Content-type' : 'application/json;charset=UTF-8'
        'Content-Type': 'multipart/form-data;charset=UTF-8'
    },
    transformRequest: [function(data) {
        if(data) {
            const formData = new FormData()
            Object.keys(data).forEach((key) => {
                if (Array.isArray(data[key])) {
                    data[key].map((item:any, index:number) => {
                        formData.append(`${key}[${index}]`, item)
                    })
                } else {
                    formData.append(key, data[key])
                }
            })

            // data = Qs.stringify(data)
            return formData
        }
    }]
})

export const rget = <P, R>(url:string, data: P) :Promise<IResponse<R>> => {
    return request({ url, method: 'GET', params: data })
}

export const rpost = <P, R>(url:string, data: P) :Promise<IResponse<R>> => {
    return request({ url, method: 'POST', data })
}

export const rput = <P, R>(url:string, data: P) :Promise<IResponse<R>> => {
    return request({ url, method: 'PUT', data })
}

export const rdelete = <P, R>(url:string, data: P) :Promise<IResponse<R>> => {
    return request({ url, method: 'DELETE', data })
}

// 异常拦截处理器
const errorHandler = (error:{message:string}) => {
    loading.close()
    console.log(`err${error}`)
    let title = '请求失败'
    if(error.message.includes('401')) {
        const { getStatus, logout } = useLayoutStore()
        if (getStatus.ACCESS_TOKEN) {
            logout()
        }
        title = '身份认证失败'
    }
    ElNotification({
        title,
        message: error.message,
        type: 'error',
        duration: 0
    })
    return Promise.reject(error)
}

request.interceptors.request.use(config => {
    const { getStatus } = useLayoutStore()
    loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.4)'
    })
    const token = getStatus.ACCESS_TOKEN
    // 如果 token 存在
    // 让每个请求携带自定义 token 请根据实际情况自行修改
    if (token) {
        config.headers['Authorization'] = token
    }
    return config
}, errorHandler)


// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
request.interceptors.response.use((response:AxiosResponse<IResponse<any>>) => {
    const { data } = response
    loading.close()
    if(data.code !== 200) {
        const title = '请求失败'
        ElNotification({
            title,
            message: data.msg,
            type: 'error'
        })
        return Promise.reject(new Error(data.msg || 'Error'))
    }
    return response.data
}, errorHandler)

