import { rpost } from '/@/utils/request'

export type stringAndNull = string | null
export type numberAndNull = number | null

interface UploadFileResponse {
    uri : string
}

export interface IHotelList {
    [id: string] : string
}

export interface IOptions {
    value: string | number,
    label: string
}

export function area(): Promise<IResponse<any>> {
    return rpost('/get/city', null)
}

export function sendSms(params: { mobile: string , event: string}): Promise<IResponse<boolean>> {
    return rpost('/sms', params)
}

export function uploadFile(params: { type: string , file: File }): Promise<IResponse<UploadFileResponse>> {
    return rpost('/upload/file', params)
}

