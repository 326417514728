import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import { IMenubarList } from '/@/type/store/layout'
import { components } from '/@/router/asyncRouter'

const Components:IObject<() => Promise<typeof import('*.vue')>> = Object.assign({},
    components,
    {
        Layout: (() => import('/@/layout/index.vue')) as unknown as () => Promise<typeof import('*.vue')>,
        Redirect: (() => import('/@/layout/redirect.vue')) as unknown as () => Promise<typeof import('*.vue')>,
        LayoutBlank: (() => import('/@/layout/blank.vue')) as unknown as () => Promise<typeof import('*.vue')>
    })


// 静态路由页面
export const allowRouter:Array<IMenubarList> = [
    {
        name: 'Login',
        path: '/Login',
        component: Components.Login,
        meta: { title: '登录', icon: 'el-icon-eleme', hidden: true }
    },
    {
        name: 'Statistics',
        path: '/',
        component: Components['Layout'],
        redirect: '/Statistics',
        meta: { title: '数据', icon: 'el-icon-s-data' },
        children: [
            {
                name: 'Statistics',
                path: '/Statistics',
                component: Components['Statistics'],
                meta: { title: '数据概览', icon: 'el-icon-s-data' }
            }
        ]
    },
    {
        name: 'Merchant',
        path: '/Merchant',
        redirect: '/Merchant',
        component: Components['Layout'],
        meta: { title: '商家管理', icon: 'el-icon-s-cooperation' },
        children: [
            {
                name: 'MerchantManage',
                path: '/Merchant',
                component: Components['Merchant'],
                meta: { title: '商家管理', icon: 'el-icon-s-cooperation', noCache: true }
            },
            {
                name: 'AddMerchant',
                path: '/Merchant/Create',
                component: Components['CreateMerchant'],
                meta: { title: '新增商家', icon: '' ,hidden: true }
            },
            {
                name: 'EditMerchant',
                path: '/Merchant/:id/Edit',
                component: Components['EditMerchant'],
                meta: { title: '编辑商家', icon: '' ,hidden: true }
            }
        ]
    },
    {
        name: 'Employee',
        path: '/Employee',
        redirect: '/Employee',
        component: Components['Layout'],
        meta: { title: '员工管理', icon: 'el-icon-user-solid' },
        children: [
            {
                name: 'EmployeeManage',
                path: '/Employee',
                component: Components['Employee'],
                meta: { title: '员工管理', icon: 'el-icon-user-solid',noCache: true }
            },
            {
                name: 'AddEmployee',
                path: '/Employee/Create',
                component: Components['CreateEmployee'],
                meta: { title: '新增员工', icon: '' ,hidden: true }
            },
            {
                name: 'EditEmployee',
                path: '/Employee/:id/Edit',
                component: Components['EditEmployee'],
                meta: { title: '编辑员工', icon: '' ,hidden: true }
            }
        ]
    },
    {
        name: 'Order',
        path: '/Order',
        redirect: '/Order',
        component: Components['Layout'],
        meta: { title: '发单列表', icon: 'el-icon-s-order' },
        children: [
            {
                name: 'OrderList',
                path: '/Order',
                component: Components['Order'],
                meta: { title: '发单列表', icon: 'el-icon-s-order', noCache: true }
            },
            {
                name: 'OrderInfo',
                path: '/Order/:id',
                component: Components['OrderInfo'],
                meta: { title: '订单详情', icon: '' ,hidden: true }
            }
        ]
    },
    {
        name: 'Room',
        path: '/Room',
        redirect: '/Room',
        component: Components['Layout'],
        meta: { title: '房间列表', icon: 'el-icon-eleme' },
        children: [
            {
                name: 'RoomList',
                path: '/Room',
                component: Components['Room'],
                meta: { title: '房间列表', icon: 'el-icon-house', noCache: true }
            }
        ]
    },
    {
        name: 'Product',
        path: '/Product',
        redirect: '/ProductList',
        component: Components['Layout'],
        meta: { title: '商品配送', icon: 'el-icon-eleme' },
        children: [
            {
                name: 'ProductGroup',
                path: '/ProductGroup',
                component: Components['ProductGroup'],
                meta: { title: '商品分类', icon: 'el-icon-ShoppingCartFull', noCache: true }
            },
            {
                name: 'ProductList',
                path: '/ProductList',
                component: Components['ProductList'],
                meta: { title: '商品列表', icon: 'el-icon-list', noCache: true }
            },
            {
                name: 'ProductAdd',
                path: '/Product/Add',
                component: Components['ProductAdd'],
                meta: { title: '添加商品', icon: '' ,hidden: true }
            },
            {
                name: 'ProductEdit',
                path: '/Product/:id/Edit',
                component: Components['ProductEdit'],
                meta: { title: '编辑商品', icon: '' ,hidden: true }
            },
            {
                name: 'ProductOrder',
                path: '/ProductOrder',
                component: Components['ProductOrder'],
                meta: { title: '配送订单', icon: 'el-icon-list', noCache: true }
            },
            {
                name: 'ProductOrderInfo',
                path: '/ProductOrder/:order_no',
                component: Components['ProductOrderInfo'],
                meta: { title: '编辑商品', icon: '' , hidden: true }
            }
        ]
    },
    {
        name: 'Scheduling',
        path: '/Scheduling',
        redirect: '/Scheduling',
        component: Components['Layout'],
        meta: { title: '月份排班', icon: 'el-icon-eleme' },
        children: [
            {
                name: 'Scheduling',
                path: '/Scheduling',
                component: Components['Scheduling'],
                meta: { title: '排班统计', icon: 'el-icon-ShoppingCartFull', noCache: true }
            },
            {
                name: 'Attendance',
                path: '/Attendance',
                component: Components['Attendance'],
                meta: { title: '考勤统计', icon: 'el-icon-list', noCache: true }
            }
        ]
    },
    // {
    //     name: 'Repair',
    //     path: '/Repair',
    //     redirect: '/Repair',
    //     component: Components['Layout'],
    //     meta: { title: '维修列表', icon: 'el-icon-s-tools' },
    //     children: [
    //         {
    //             name: 'RepairList',
    //             path: '/Repair',
    //             component: Components['Repair'],
    //             meta: { title: '维修列表', icon: 'el-icon-s-tools' }
    //         }
    //     ]
    // },
    // {
    //     name: 'RoomService',
    //     path: '/RoomService',
    //     redirect: '/RoomService',
    //     component: Components['Layout'],
    //     meta: { title: '客房服务', icon: 'el-icon-eleme' },
    //     children: [
    //         {
    //             name: 'RoomServiceList',
    //             path: '/RoomService',
    //             component: Components['RoomService'],
    //             meta: { title: '客房服务', icon: 'el-icon-office-building' }
    //         }
    //     ]
    // },
    {
        name: 'RedirectPage',
        path: '/redirect',
        component: Components['Layout'],
        meta: { title: '重定向页面', icon: 'el-icon-eleme', hidden: true },
        children: [
            {
                name: 'Redirect',
                path: '/redirect/:pathMatch(.*)*',
                meta: {
                    title: '重定向页面',
                    icon: ''
                },
                component: Components.Redirect
            }
        ]
    },
    {
        name: 'ErrorPage',
        path: '/:pathMatch(.*)*',
        meta: { title: '错误页面', icon: 'el-icon-eleme' , hidden: true },
        component: Components['Layout'],
        redirect: '/ErrorPage/404',
        children: [
            {
                name: '401',
                path: '/ErrorPage/401',
                component: Components['401'],
                meta: { title: '401', icon: 'el-icon-s-tools' , hidden: true }
            },
            {
                name: '404',
                path: '/ErrorPage/404',
                component: Components['404'],
                meta: { title: '404', icon: 'el-icon-s-tools', hidden: true }
            }
        ]
    }
]

const router = createRouter({
    history: createWebHashHistory(), // createWebHistory
    routes: allowRouter as RouteRecordRaw[]
})

export default router
