<template>
    <keep-alive>
        <router-view />
    </keep-alive>
</template>

<script lang='ts'>
import { defineComponent } from 'vue'
import { changeThemeDefaultColor } from '/@/utils/changeThemeColor'

export default defineComponent ({
    name: 'App',
    setup() {
        changeThemeDefaultColor()
    }
})
</script>

<style>
    .card-main {
        height: 86vh;
    }

    .not-data-tips {
        position: absolute;
        left: 50%;
        top: 35%;
        transform: translate(-50%, -50%);
        color: #999;
    }
</style>
