import { rpost } from '/@/utils/request'
import { Paging } from '/@/utils/requestType'
import { numberAndNull } from '/@/api/common'

interface MerchantListParasm extends Paging {
    status: numberAndNull,
    shop_type: numberAndNull,
}

// 列表
export interface MerchantListInfo {
    address: string
    city: string
    created_at: string
    detailed_address: string
    district: string
    hotel_name: string
    id: number
    image: string
    job: string
    not_pass_remark: string
    overdrafted: string
    phone: string
    province:string
    shop_type: number
    status: number
    user_name: string
    
}

export interface MerchantListResponse {
    list: MerchantListInfo[],
    total: number
}

// 详情
export interface MerchantInfoResponse extends MerchantListInfo{
    province_id: number
    city_id: number
    district_id: number
    lat: string
    lng: string
}

interface EditParams {
    id :number | null
    shop_type: number | string
    code :string | void
    name: string
    address	: string	
    province_id: number | string	
    city_id: number | string	
    district_id: number | string	
    user_name: string	
    job: string	
    phone: string	
    image: string	
    lng: string
    lat: string
}

 interface HotelListResponse {
    id: number,
    hotel_name: string
}

export function index(params: MerchantListParasm): Promise<IResponse<MerchantListResponse>> {
    return rpost('merchant/list', params)
}

export function show(params: {hotel_id: number}): Promise<IResponse<MerchantInfoResponse>> {
    return rpost('merchant/info', params)
}

export function del(params: {hotel_id: number}): Promise<IResponse<boolean>> {
    return rpost('merchant/del', params)
}

export function add(params: EditParams): Promise<IResponse<boolean>> {
    return rpost('merchant/add', params)
}

export function hotelListOptions() : Promise<IResponse<HotelListResponse[]>> {
    return rpost('merchant/list/all', null)
}

