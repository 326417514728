import { area as getCityList } from '/@/api/common'
import { hotelListOptions } from '/@/api/merchant'

class Common {
    protected area = []

    protected hotelList = [] as any[]

    protected changAreaKey(data: any[]): any {
        const arr: { label: string; value: number; children: { label: string; value: number; children: any }[] }[] = []
        data.map((item: { name: string; province_id: number; city: any[] }) => {
            const province = {
                label: item.name,
                value: item.province_id,
                children: item.city.map(city => {
                    return {
                        label: city.name,
                        value: city.city_id,
                        children: city.county.map((county: { name: any; county_id: any }) => {
                            return {
                                label: county.name, value: county.county_id
                            }
                        })
                    }
                })
            }
            arr.push(province)
        })
        return arr
    }

    public getAreaData = async(refresh = false): Promise<any[]> => {
        if (! this.area.length || refresh) {
            const { result } = await getCityList()
            this.area = this.changAreaKey(result)
        }
        return this.area
    }

    public getHotelListOptions = async(refresh = false): Promise<any[]> => {
        if (! this.hotelList.length || refresh) {
            const { result } = await hotelListOptions()
            const arr = result.map((item: {id : number, hotel_name: string}) => {
                return { value: item.id, label: item.hotel_name }
            })
            arr.unshift({ value: 0 , label: '全部商家' })
            this.hotelList = arr
        }
  
        // 深拷贝
        return JSON.parse(JSON.stringify(this.hotelList))
    }

    public clearHotelListOptions() {
        this.hotelList = []
    }

}

export const commonData = new Common()



